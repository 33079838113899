import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import SearchResultsPage from "@components/admin/SearchResultPage";
import SearchResultsTableComponent from "@components/admin/users/search/SearchResultsTableComponent";
import { EsUserResponse } from "@reshopper/admin-client";

export default withAdminMenuLayout(function () {
    return <SearchResultsPage<EsUserResponse>
        resultsComponent={SearchResultsTableComponent}
        onFetchResults={async (query) => adminClient().adminUserSearchGet({
            query
        })}
    />
});