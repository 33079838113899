import InternalLink from "@components/InternalLink";
import WebpImage from "@components/WebpImage";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { EsUserResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";

interface Props {
  searchResults: EsUserResponse[];
}

export default function SearchResultsTableComponent(props: Props) {

  function evaluateBlocked(blocked: boolean) {
    if (blocked) {
      return <BlockIcon color="error" />
    }
  }
  function renderRow(user: EsUserResponse) {
    return <>
      <TableCell>
        <WebpImage src={user.largeProfilePictureUrl} width={32} height={32} />
      </TableCell>
      <TableCell>
        <InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(user.id)}>
          {user.firstName + " " + user.lastName}
        </InternalLink>
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.zipCode ?? "No Address"}</TableCell>
      <TableCell>{toDanishDateFormat(user.createdUtc)}</TableCell>
      <TableCell>{evaluateBlocked(!!user.blockedUtc)}</TableCell>
    </>;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Zipcode</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Blocked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.searchResults?.map((user, index) => (
              <TableRow
                key={index.toString()}
                style={{
                  background: index % 2 ?
                    "#F9F9F9" :
                    "white"
                }}
              >
                {renderRow(user)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>

  )
}

